// import { createPortal } from "react-dom";
import useScript from "./hooks/useScript";
import MenuBodyContent from "./MenuBodyContent";
// const { HashLink } = require("react-router-hash-link");
const {Helmet} = require("react-helmet");
// const in_html = require('./menu.html?raw')

function MenuBody(){
  // useScript("https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.bundle.min.js", null, false);
  useScript("js/scripts.js", null, false);

  // useScript("js/jquery-3.7.1.min.js", null, false);
  // useScript("js/slick.js", null, false);
  // useScript("js/sliding-menu.js", null, false);

  // reader.getText('ab.txt').then(function (data:string) {
  //   console.log(data);
  // });

  return <>
    <Helmet>
      <meta name="twitter:title" content="Dori Catering | Menu" />
      <meta property="og:title" content="Dori Catering | Menu" />
      <title>Dori Catering | menu</title>

      {/* <link href="css/slick.css" rel="stylesheet" />
      <link href="css/slick-theme.css" rel="stylesheet" />
      <link href="css/sliding-menu.css" rel="stylesheet" /> */}
    </Helmet>
    {/* <div dangerouslySetInnerHTML={{__html: in_html}}></div> */}
    <MenuBodyContent />


    {/* {createPortal(
      <>
        <script src="js/jquery-3.7.1.min.js"></script>
        <script src="js/slick.min.js"></script>
        <script src="js/sliding-menu.js"></script>
      </>,
      document.body
    )} */}
  </>

}

export default MenuBody
