import { useEffect } from "react"

const useScript = (url, integrity, async = false, where='body', crossOrigin = "anonymous") => {
  useEffect(() => {
    const script = document.createElement("script")
    script.src = url
    script.async = async
    if (integrity) {script.integrity = integrity}
    script.crossOrigin = crossOrigin
    var target_element = document.getElementsByTagName(where)[0];
    target_element.appendChild(script)

    return () => {target_element.removeChild(script);}
  }, [url, integrity, async, where, crossOrigin])
}

export default useScript;
