// import { useState } from "react";
// import MenuItem from "./MenuItem";
function SubGroup({groups_with_subgroups, cur_group, cur_subgroup, onSubGroupChange} : 
        {groups_with_subgroups:Record<string, string[]>, cur_group:string, 
            cur_subgroup:any, onSubGroupChange:any}) {

    var values:string[] = groups_with_subgroups[cur_group];

    if (values === undefined) return (null);
        
        const rows:any = [];
        values.forEach((value:string, index:number) =>{
            rows.push(
                <label key={index} 
                    className="badge subgroupbadge rounded-0 btn btn-primary btn-sm me-3 mb-3">
                    <input type="radio" className="me-1" name="menusubgroup" value={value} 
                        style={{verticalAlign: "text-top"}}
                        checked={value==cur_subgroup}
                        onChange={onSubGroupChange}/>
                    {value}
                </label>);

        });

        if (rows.length)
            return <>
                <div className="mb-2 muted">Choose a sub-category</div>{rows}
            </>;
        return null;
}

export default SubGroup;
