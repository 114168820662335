// import { useState } from "react";
// import MenuItem from "./MenuItem";
function Group({groups_with_subgroups, cur_group, onGroupChange} : 
        {groups_with_subgroups:Record<string, string[]>, cur_group:string, 
            onGroupChange:any}) {

    var group_names:string[] = Object.keys(groups_with_subgroups);
    // console.log(groups_with_subgroups); // skkn
    // if (group_names === undefined) return (null);
        
        const rows:any = [];
        group_names.forEach((group_name:string, index:number) =>{
            if (group_name.toLowerCase().startsWith('not')) return;
            rows.push(
                <label key={index} 
                    className="badge groupbadge rounded-0 btn btn-sm me-3 mb-3 px-3">
                    <input type="radio" className="me-1" name="menugroup" value={group_name} 
                        style={{verticalAlign: "text-top"}}
                        checked={group_name==cur_group}
                        onChange={onGroupChange}/>
                    {group_name}
                </label>);

        });

        return rows;
}

export default Group;
