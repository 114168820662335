// import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import { NavLink, Routes, Route } from 'react-router-dom';
// import { useEffect, useState }from'react';
// import HomeBody from './HomeBody';
import MenuBody from './MenuBody';

// import reportWebVitals from './reportWebVitals';

// const projects = ReactDOM.createRoot(
//   document.getElementById('projects') as HTMLElement
// );

// const outside = {
//   value: 2,
//   [callbacks:any]: [],
//   addCallback: function (callback:any) { this.callbacks.push(callback); },
//   setValue: function (value:any) { 
//       this.value = value; 
//       this.callbacks.forEach(
//           callback => callback(this.value)
//       );
//   }
// };

(window as any).setMenuGroup = null;

const body_div = ReactDOM.createRoot(
  document.getElementById('page-top') as HTMLElement
);
// function Home() {return <HomeBody/>}
// function Menu() {return <MenuBody/>}
body_div.render(
  // <React.StrictMode>
  <BrowserRouter> 
    <Routes>
      <Route path='/' element={<MenuBody/>}></Route>
      <Route path='/index' element={<MenuBody/>}></Route>
      <Route path='/index.html' element={<MenuBody/>}></Route>
      <Route path='/menu' element={<MenuBody/>}></Route>
      {/* <Route path='/contact' component={Contact}></Route> */}
    </Routes>
  </BrowserRouter>
  // </React.StrictMode>

)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

// const menu_link = ReactDOM.createRoot(
//   document.getElementById('menu_link') as HTMLElement
// );

// menu_link.render(
//   <BrowserRouter>
//     <li><NavLink to='/menu' className='nav-link'>MenuHead</NavLink></li>
//   </BrowserRouter>
// );

// var menu_html = require('./menu.html')
// function Menu()  {
//   let [htmlFileString, setHtmlFileString]: [any, any] = useState();
//   async function fetchHtml() {
//     setHtmlFileString(await (await fetch(`menu.html`)).text());
//   }
//   useEffect(() => {
//     fetchHtml();
//   }, []);

//   return (<div dangerouslySetInnerHTML={{__html: htmlFileString}}></div>);

// }

// const Menu = () => {
//   return (
//     <>
//       <MenuHead />
//       <MenuBody />
//     </>
//   )
// }

// const Main = () => (
//   <Routes>
//     {/* <Route path='/' component={Home}></Route> */}
//     <Route path='/menu' element={Menu()}></Route>
//     {/* <Route path='/contact' component={Contact}></Route> */}
//   </Routes>
// );
