function MenuItem({menu_item} : {menu_item: any}) {
  return (
    <div className="row group mt-2 mb-4 mt-lg-2 text-black">
      <div className="col-12 col-md-2"></div>
      <div className="col-11 col-md-7">
        <div className="lead  text-left text-uppercase">
          <span className="text-break fs-3" dangerouslySetInnerHTML={{ __html: menu_item.name }}></span>
          <span><br/></span>
        </div>
        <div className="mt-1 lead text-black">{menu_item.description}</div>
      </div>
      <div className="col-11 col-md-1 mt-3">
          {menu_item.price}</div>
    </div>
  );
}

export default MenuItem;
